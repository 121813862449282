// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import {
  PERSONAL_ACCESS_PORTABILITY_ROUTE_PROPS,
  PERSONAL_CONSENTS_ROUTE_PROPS,
  PERSONAL_DOCUMENTATION_ROUTE_PROPS,
  PERSONAL_EDIT_ADDRESS_ROUTE_PROPS,
  PERSONAL_EDIT_EMAIL_ROUTE_PROPS,
  PERSONAL_EDIT_NUMBER_ROUTE_PROPS,
  PERSONAL_EDIT_PHOTO_ROUTE_PROPS,
  PERSONAL_LANDING_ROUTE_PROPS,
} from './constants/personal-router.constants';
import {
  ACCESS_REQUEST_ROUTE_PROPS,
  PORTABILITY_REQUEST_ROUTE_PROPS,
} from '../../views/access-portability/components/router/constants/access-portability-router.constants';
import { WorkInProgressComponent } from 'components/working-in-progress/work-in-progress.component';
// Views
const PersonalConsentsView = lazy(
  () => import('containers/personal/views/consents/profile-consents.view')
);
const PersonalLandingView = lazy(
  () => import('containers/personal/views/landing/personal-landing.view')
);
const PersonalPhotoView = lazy(() => import('containers/personal/views/photo/personal-photo.view'));
const PersonalAddressView = lazy(
  () => import('containers/personal/views/address/personal-address.view')
);
const PersonalEmailView = lazy(() => import('containers/personal/views/email/personal-email.view'));
const PersonalNumberView = lazy(
  () => import('containers/personal/views/number/personal-number.view')
);
const PersonalAccessPortabilityView = lazy(
  () => import('containers/personal/views/access-portability/access-portability.component')
);
const PersonalAccessPortabilityRequestView = lazy(
  () =>
    import('containers/personal/views/access-portability/views/request/request-access.component')
);
const PersonalAccessPortabilityRequestPortabilityView = lazy(
  () =>
    import(
      'containers/personal/views/access-portability/views/request-portability/request-portability.component'
    )
);
const PersonalUploadDocumentationView = lazy(
  () => import('containers/personal/views/upload-documentation/personal-upload-documentation.view')
);

const PersonalRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route {...PERSONAL_LANDING_ROUTE_PROPS} element={<PersonalLandingView />} />
    <Route {...PERSONAL_EDIT_EMAIL_ROUTE_PROPS} element={<PersonalEmailView />} />
    <Route {...PERSONAL_EDIT_NUMBER_ROUTE_PROPS} element={<PersonalNumberView />} />
    <Route {...PERSONAL_EDIT_ADDRESS_ROUTE_PROPS} element={<PersonalAddressView />} />
    <Route {...PERSONAL_CONSENTS_ROUTE_PROPS} element={<PersonalConsentsView />} />
    <Route {...PERSONAL_DOCUMENTATION_ROUTE_PROPS} element={<PersonalUploadDocumentationView />} />
    <Route {...PERSONAL_ACCESS_PORTABILITY_ROUTE_PROPS} element={<PersonalAccessPortabilityView />}>
      <Route {...ACCESS_REQUEST_ROUTE_PROPS} element={<PersonalAccessPortabilityRequestView />} />
      <Route
        {...PORTABILITY_REQUEST_ROUTE_PROPS}
        element={<PersonalAccessPortabilityRequestPortabilityView />}
      />
    </Route>
    <Route {...PERSONAL_EDIT_PHOTO_ROUTE_PROPS} element={<PersonalPhotoView />} />
  </Routes>
);

export default PersonalRouterComponent;
