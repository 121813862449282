// Vendors
import { CharactersEnum } from '@openbank/cf-ui-static-data';
import React from 'react';
// Components
import {
  AssetComponent,
  LineSeparatorComponent,
  ProductDetailComponent,
  ResumeItemComponent,
  SidebarComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import ProfilePersonalNameTitleComponent from 'containers/personal/views/landing/components/name/components/title/profile-personal-name-title.component';
// Constants
import {
  PERSONAL_SIDEBAR_CC_ALIAS_PROPS,
  PERSONAL_SIDEBAR_CC_COLLECTIVE_PROPS,
  PERSONAL_SIDEBAR_CC_DESCRIPTION_PROPS,
  PERSONAL_SIDEBAR_CC_HOURS_EDIT_ASSET_PROPS,
  PERSONAL_SIDEBAR_CC_LINE_SEPARATOR_PROPS,
  PERSONAL_SIDEBAR_CC_OFFICE_PROPS,
  PERSONAL_SIDEBAR_CC_PROPS,
  PERSONAL_SIDEBAR_CC_SEGMENT_PROPS,
} from './constants/personal-sidebar-cc.constants';
// Hooks
import { PersonalSidebarCCHook } from './hooks/personal-sidebar-cc.hook';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
import {
  PersonalSidebarComponentStyled,
  PersonalSidebarContentComponentStyled,
} from './personal-sidebar-cc.component.styled';
// Translations
import {
  ALIAS,
  COLLECTIVE,
  CONTACT_HOURS,
  OFFICE,
  SEGMENT,
  TITLE,
} from './translations/personal-sidebar-cc.translations';
// Utils
import { isCC } from 'utils/app.utils';

export const PersonalSidebarCCComponent = (): React.ReactElement | null => {
  const { alias, formatMessage, userName } = PersonalSidebarCCHook();

  return isCC() ? (
    <SidebarComponentStyled>
      <SidebarComponent
        {...PERSONAL_SIDEBAR_CC_PROPS}
        title={<FormattedMessageComponent id={TITLE} />}
      >
        <PersonalSidebarComponentStyled>
          <TitleComponent {...PERSONAL_SIDEBAR_CC_DESCRIPTION_PROPS}>{userName}</TitleComponent>
          <ResumeItemComponent
            {...PERSONAL_SIDEBAR_CC_ALIAS_PROPS}
            label={<FormattedMessageComponent id={ALIAS} />}
            value={alias}
          />
          <LineSeparatorComponent {...PERSONAL_SIDEBAR_CC_LINE_SEPARATOR_PROPS} />
          <ProfilePersonalNameTitleComponent />
          <LineSeparatorComponent {...PERSONAL_SIDEBAR_CC_LINE_SEPARATOR_PROPS} />
          <ProductDetailComponent title={formatMessage({ id: CONTACT_HOURS })}>
            <PersonalSidebarContentComponentStyled>
              <TitleComponent>{CharactersEnum.NO_VALUE}</TitleComponent>
              <AssetComponent {...{ ...PERSONAL_SIDEBAR_CC_HOURS_EDIT_ASSET_PROPS }} />
            </PersonalSidebarContentComponentStyled>
          </ProductDetailComponent>
          <LineSeparatorComponent {...PERSONAL_SIDEBAR_CC_LINE_SEPARATOR_PROPS} />
          <ResumeItemComponent
            {...PERSONAL_SIDEBAR_CC_SEGMENT_PROPS}
            label={<FormattedMessageComponent id={SEGMENT} />}
            value={CharactersEnum.NO_VALUE}
          />
          <ResumeItemComponent
            {...PERSONAL_SIDEBAR_CC_COLLECTIVE_PROPS}
            label={<FormattedMessageComponent id={COLLECTIVE} />}
            value={CharactersEnum.NO_VALUE}
          />
          <ResumeItemComponent
            {...PERSONAL_SIDEBAR_CC_OFFICE_PROPS}
            label={<FormattedMessageComponent id={OFFICE} />}
            value={CharactersEnum.NO_VALUE}
          />
        </PersonalSidebarComponentStyled>
      </SidebarComponent>
    </SidebarComponentStyled>
  ) : null;
};
