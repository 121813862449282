// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import AccessPortabilityRequestStep2ActionsHook from './hooks/access-portability-request-step2-actions.hook';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
// Translations
import translations from './translations/access-portability-step2-actions.translations';

const AccessPortabilityRequestStep2ActionsComponent = (): React.ReactElement => {
  const { handleRequestAccessButtonClick } = AccessPortabilityRequestStep2ActionsHook(),
    { challenged, fetching } = AccessPortabilityContextConsumerHook();

  return (
    <WizardStepFooterComponent
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        onClick: handleRequestAccessButtonClick,
        disabled: challenged || fetching,
        loading: fetching,
        children: <FormattedMessageComponent id={translations.CONFIRM} />,
      }}
    />
  );
};

export default AccessPortabilityRequestStep2ActionsComponent;
