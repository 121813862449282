// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { WIZARD_STEP_FOOTER_PROPS } from './constants/personal-address-step2-actions.constants';
// Hooks
import { usePersonalAddressStep2ActionsHook } from './hooks/personal-address-step2-actions.hook';
// Translations
import { NEXT } from './translations/personal-address-step2-actions.translations';
// Types
import { PersonalAddressStep2ActionsComponentType } from './types/personal-address-step2-actions.component.type';

export const PersonalAddressStep2ActionsComponent = ({
  newAddress,
}: PersonalAddressStep2ActionsComponentType): React.ReactElement => {
  const { challenge, handleNextButtonClick } = usePersonalAddressStep2ActionsHook({ newAddress });

  return (
    <WizardStepFooterComponent
      {...WIZARD_STEP_FOOTER_PROPS}
      rightButtonConfiguration={{
        children: <FormattedMessageComponent id={NEXT} />,
        disabled: !challenge,
        onClick: handleNextButtonClick,
      }}
    />
  );
};
