// Enums
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Resolvers
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';
// Types
import { RequestAccessStep2ChallengeBuilderReturnHandlersType } from './types/request-access-step2-challenge-builder-return.handlers.type';
import { AccessPortabilityRequestStep2BuilderHandlersType } from './types/request-access-step2-challenge-builder.handlers.type';
import { RequestAccessStep2ChallengeMethodBuilderHandlersType } from './types/request-access-step2-challenge-method-builder.handlers.type';

const manageErrorOnRequestAccessEvent = ({
  setChallenged,
  setResult,
}: Pick<AccessPortabilityRequestStep2BuilderHandlersType, 'setChallenged' | 'setResult'>): void => {
  setChallenged(false);
  setResult(OperativeResultsEnumeration.ERROR);
};

const manageSuccessOnRequestAccessEvent = ({
  progressId,
  setChallenged,
  setProgressId,
}: RequestAccessStep2ChallengeMethodBuilderHandlersType): void => {
  setChallenged(false);
  if (progressId) {
    setProgressId(progressId);
  }
};

const challengeRetryButtonClickHandler = ({ setError }: ChallengeRetryButtonClickHandlerType) =>
  setError(false);

const setupStartedChallengeEventHandler = ({
  setChallenged,
}: Pick<AccessPortabilityRequestStep2BuilderHandlersType, 'setChallenged'>): any => {
  setChallenged(true);
};

const RequestAccessStep2ChallengeHandlers = (
  props: AccessPortabilityRequestStep2BuilderHandlersType
): RequestAccessStep2ChallengeBuilderReturnHandlersType => ({
  handleManageErrorOnRequestAccessEvent: () => manageErrorOnRequestAccessEvent(props),
  handleManageSuccessOnRequestAccessEvent: ({ progressId }) =>
    manageSuccessOnRequestAccessEvent({ ...props, progressId }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler(props),
  handleSetupStartedChallengeEvent: () => setupStartedChallengeEventHandler(props),
});

export default RequestAccessStep2ChallengeHandlers;
